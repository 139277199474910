/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:c6284ff4-a4b0-456c-bd34-5bf8501dd85f",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_stJtarxHQ",
    "aws_user_pools_web_client_id": "6jl9jqfh87md5u1s0dc5ie78p8",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://xfpg5imd35hqzodzfusxrda5em.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-cj6227ku2zh7zdzd3xukymcahy"
};


export default awsmobile;
